<template>
  <div class="app-container">
    <div class="left_box">
      <UserCard v-if="userInfo !== null" :groupInfo="userInfo" />
      <div class="club_box">
        所在群(
        <span>{{ total }}</span>
        )
      </div>
      <div
        class="bottom_box"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
        infinite-scroll-distance="80px"
      >
        <UserGroupCard
          :memberList="groupList"
          @reciverUserGroup="reciverUserGroup"
        />
        <p v-if="loading" style="textAlign: center;">加载中...</p>
        <p v-if="noMore" style="textAlign: center;">没有更多了</p>
      </div>
    </div>
    <div
      class="right_box"
      v-infinite-scroll="messageLoad"
      infinite-scroll-disabled="disabled2"
      infinite-scroll-distance="80px"
    >
      <SingleMessage
        v-for="item in messageList"
        :key="item.id"
        :singleInfo="item"
      />
      <p v-if="loading2" style="textAlign: center;">加载中...</p>
      <p v-if="noMore2" style="textAlign: center;">没有更多了</p>
    </div>
  </div>
</template>

<script>
import { addTabs } from '@/assets/js/addTabs'
import { getSingleUserInfo, getMembers, getMessageList } from '@/api/whatsApp'
import UserGroupCard from './components/UserGroupCard.vue'
import UserCard from './components/UserCard.vue'
import SingleMessage from '../search/componentens/SingleMessage.vue'

export default {
  name: 'UserDetailPage',
  components: { UserGroupCard, UserCard, SingleMessage },
  data() {
    return {
      userInfo: null,
      groupList: [],
      messageList: [],
      total: 0,
      page_index: 1,
      loading: false,
      // 消息
      pageIndex: 1,
      loading2: false,
      total2: 0,
      groupId: null
    }
  },

  async created() {
    await this.getUserInfo(this.$route.params.userId)
    this.getMembersList()
    this.getMessageListInfo()
  },
  computed: {
    noMore() {
      return this.groupList.length >= this.total
    },
    disabled: {
      get() {
        return this.loading || this.noMore
      },
      set() {}
    },
    noMore2() {
      return this.messageList.length >= this.total2
    },
    disabled2: {
      get() {
        return this.loading2 || this.noMore2
      },
      set() {}
    }
  },
  methods: {
    async getUserInfo(id) {
      const that = this
      const res = await getSingleUserInfo(id)
      this.userInfo = Object.assign({}, res)
      addTabs(that, res.name, res.id, '/user/detail/')
    },
    // 获得群
    async getMembersList() {
      const data = {
        user: this.$route.params.userId,
        page_index: this.page_index
      }
      this.loading = true
      const res = await getMembers(data)
      this.loading = false
      if (res.items.length < res.page_size) {
        this.disabled = true
      }
      this.groupList = [...this.groupList, ...res.items]

      this.total = res.count
    },
    load() {
      this.page_index += 1
      this.getMembersList()
    },
    // 获得消息
    messageLoad() {
      this.pageIndex += 1
      this.getMessageListInfo()
    },
    async getMessageListInfo() {
      const data = {
        author: this.$route.params.userId,
        page_index: this.pageIndex,
        group: this.groupId
      }
      const res = await getMessageList(data)
      if (res.items.length < res.page_size) {
        this.disabled2 = true
      }
      this.messageList = [...this.messageList, ...res.items]

      this.total2 = res.count
    },
    // 群检索
    reciverUserGroup(val) {
      this.pageIndex = 1
      this.messageList = []
      this.groupId = val
      this.getMessageListInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  justify-content: space-between;
  .left_box {
    width: 300px;
    height: calc(100vh - 120px);
    padding: 10px;
    overflow-y: auto;
    margin-top: 10px;
    border: 1px solid #e5e5e5;

    box-shadow: 0 1px 0px 0 #d8d9d9;
    border-radius: 5px;

    .club_box {
      height: 38px;
      background: #f2f4f8;
      line-height: 38px;
      padding-left: 10px;
      span {
        color: #ff5400;
      }
    }
    .bottom_box {
      height: calc(100% - 310px);
      // background: pink;
      padding: 10px 0;
      overflow-y: auto;
    }
  }

  .right_box {
    width: calc(100% - 300px);
    height: calc(100vh - 120px);
    margin: 10px 0 0 10px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 0px 0 #d8d9d9;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
    .nomore_box {
      text-align: center;
      font-size: 14px;
      color: #949698;
    }
  }
}
</style>
